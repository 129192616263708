<template>
  <div>
    <v-list three-line>
      <transition-group name="fade" tag="div">
        <kurcc-site-branch-list-item v-for="(branch, index) in branches" :key="index" :branch="branch">
          <v-col v-if="index !== branches.length -1" class="pa-0" cols="12">
            <v-divider/>
          </v-col>
        </kurcc-site-branch-list-item>
      </transition-group>
    </v-list>
    <kurcc-infinite-loading :filter="[`site_id=${$route.params.site_id}`]" :items.sync="branches"
                            action-name="getAllBranches"/>
  </div>
</template>

<script>
export default {
  name: 'KurccSiteBranchList',
  components: {
    KurccSiteBranchListItem: () => import('@/modules/more/components/KurccSiteBranchListItem'),
    KurccInfiniteLoading: () => import('@/modules/app/components/KurccInfiniteLoading')
  },
  data () {
    return {
      branches: []
    }
  }
}
</script>
